import React, { useState } from "react";
import { FaBars, FaCar } from "react-icons/fa";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      className="px-6 py-4"
      style={{
        background:
          "linear-gradient(to right, rgba(106, 90, 205, 0.8), rgba(0, 0, 255, 0.2))",
      }}
    >
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center text-white text-l ">
          <p className="text-white">JJ Cars</p>
          <FaCar className="text-white ml-2" />
        </div>
        <div className="hidden lg:block">
          <a href="/" className="text-white px-4">
            Home
          </a>
          <a href="/about" className="text-white px-4">
            About
          </a>
          <a href="/contact" className="text-white px-4">
            Contact
          </a>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu}>
            <FaBars className="text-white" />
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="mt-3 block lg:hidden">
          <a href="/" className="block text-white px-4">
            Home
          </a>
          <a href="/about" className="block text-white px-4">
            About
          </a>
          <a href="/contact" className="block text-white px-4">
            Contact
          </a>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
