import React from "react";
import NavBar from "./NavBar";

const HeroSection = () => {
  return (
    <>
      <NavBar />
      <div
        className="relative h-96 flex items-center overflow-hidden"
        style={{
          background:
            "linear-gradient(to right, rgba(106, 90, 205, 0.8), rgba(0, 0, 255, 0.2))",
        }}
      >
        <div className="w-2/3 text-left pl-10 z-10">
          <h1 className="text-white text-6xl">Welcome to Our Car Gallery</h1>
          <p className="text-white mt-4 text-2xl">
            Enjoy our collection of 3D car models.
          </p>
        </div>

        <div className="w-1/3 flex justify-end items-center pr-10 z-10 absolute right-0 top-0 bottom-0">
          <img src="/img/car.svg" alt="Background" className="svg-car" />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
