import React, { useState } from 'react';
import CarModelViewer from './CarModelViewer';
import Modal from './Modal';

const CarCard = ({ car }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scale, setScale] = useState([3, 3, 3]);

  const openModal = () => {
    setIsOpen(true);
    setScale([5, 5, 5]); // Increase the scale when opening the modal
  };

  const closeModal = () => {
    setIsOpen(false);
    setScale([3, 3, 3]); // Reset the scale when closing the modal
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg m-4">
      <div onClick={openModal}>
        <CarModelViewer url={car.modelUrl} scale={scale} className="w-full h-64" />
      </div>
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-center">{car.name}</div>
      </div>

      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="p-4 h-auto">
          <h2 className="text-2xl font-bold mb-4 text-center">{car.name}</h2>
          <CarModelViewer url={car.modelUrl} scale={scale} />
        </div>
      </Modal>
    </div>
  );
};

export default CarCard;
