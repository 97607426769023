import React, { useState } from "react";
import CarCard from "./CarCard";
import carData from "./CarData";
import { FaSearch } from "react-icons/fa";

const CarGallery = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="flex flex-col items-center px-4 py-6">
      <div className="relative w-full sm:w-1/2 lg:w-1/3 mt-4">
        <FaSearch className="absolute text-gray-400 left-3 top-3" />
        <input
          type="text"
          className="pl-10 pr-3 py-2 w-full border-2 border-black rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
          placeholder="Search cars..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 place-items-center mt-6 w-full">
        {carData
          .filter((car) =>
            car.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((car, index) => (
            <CarCard key={index} car={car} />
          ))}
      </div>
    </div>
  );
};

export default CarGallery;
