import React, { Suspense, useRef } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { useFrame } from "@react-three/fiber";

const Model = ({ url, scale }) => {
  const ref = useRef();
  const gltf = useLoader(GLTFLoader, url);

  gltf.scene.traverse((object) => {
    if (object.isMesh) {
      object.geometry.center();
    }
  });

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.001;
    }
  });

  return <primitive ref={ref} object={gltf.scene} scale={scale} />;
};

const CarModelViewer = ({ url, scale = [3, 3, 3] }) => {
  return (
    <div className="w-full h-full">
      <Canvas camera={{ position: [0, 10, -2], target: [0, 0, 0] }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <Suspense fallback={null}>
          <Model url={url} scale={scale} />
        </Suspense>
        <OrbitControls
          minPolarAngle={Math.PI / 2.5}
          maxPolarAngle={Math.PI / 2.5}
          enableZoom={false}
        />
      </Canvas>
    </div>
  );
};

export default CarModelViewer;
