const carData = [
  {
    name: "Volkswagen",
    modelUrl: "/Models/Edited/vw_new.glb",
  },
  {
    name: "HondaRS",
    modelUrl: "/Models/Edited/hondaRS.glb",
  },
  {
    name: "Hiundai i30",
    modelUrl: "/Models/Edited/hundai_new.glb",
  },
  {
    name: "Audi Q8",
    modelUrl: "/Models/Edited/audi_new.glb",
  },
  {
    name: "Example 1",
    modelUrl: "/Models/Edited/vw_new.glb",
  },
  {
    name: "Example 2",
    modelUrl: "/Models/Edited/hondaRS.glb",
  },
  {
    name: "Example 3",
    modelUrl: "/Models/Edited/hundai_new.glb",
  },
  {
    name: "Example 4",
    modelUrl: "/Models/Edited/audi_new.glb",
  },
];

export default carData;
